@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --tui-font-text: 'Lexend', sans-serif;
    // --tui-font-text-m: normal 1rem/1.5rem var(--tui-font-text);
    // --tui-font-text-s: normal 0.875rem/1.25rem var(--tui-font-text);
    --tui-radius-l: 0.25rem;
    --tui-radius-m: 0.25rem;
    --tui-radius-s: 0;
    --tui-radius-xs: 0;
    // --tui-height-l: 4.375rem;
    --tui-primary: rgb(0, 126, 189);
    --tui-primary-hover: rgb(3, 111, 165);
    --tui-primary-active: rgb(3, 107, 160);
    --tui-primary-text: #fff;
    --pomodoro-session-color: #439acd;
    --todo-tooltip-color: #0075c2;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Lexend' !important;
    height: auto;
    width: 100%;
}

::ng-deep html,
body {
    font-family: 'Lexend' !important;
}

// apply large icon size when boxicons are used <i class="bx bx-..."></i>
i.bx {
    @apply text-xl;
}

::-webkit-scrollbar {
    position: relative;
    width: 5px;
    background: #ececec;
    height: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0.5px 1px #929bac;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #929bac;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: grey;
}

button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.image-container {
    position: relative;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
}

.error-message {
    position: absolute;
    top: 80%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.1;
    color: #000;
}

.no-image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1a1a1a80;
    opacity: 0.25;
    z-index: 0;
    border-radius: 5px;
}

/* Scrollbar customization */
.custom-scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #b9b8b8 transparent; /* Thumb and track colors */

    /* For WebKit browsers */
    &::-webkit-scrollbar {
        height: 6px; /* Adjust scrollbar height for horizontal scrolling */
    }

    &::-webkit-scrollbar-track {
        background: transparent; /* Track background */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c0c0c0; /* Thumb color */
        border-radius: 10px; /* Rounded corners for thumb */
        border: none; /* No border */
    }

    /* Hide scrollbar indicators */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a0; /* Slightly darker on hover */
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: #808080; /* Darker when active */
    }
}
